!(function (r) {
  "use strict";
  function c(t) {
    return (
      /In/.test(t) || 0 <= r.inArray(t, r.fn.textillate.defaults.inEffects)
    );
  }
  function u(t) {
    return (
      /Out/.test(t) || 0 <= r.inArray(t, r.fn.textillate.defaults.outEffects)
    );
  }
  function a(t) {
    return "true" !== t && "false" !== t ? t : "true" === t;
  }
  function o(t) {
    var e = t.attributes || [],
      i = {};
    return (
      e.length &&
        r.each(e, function (t, e) {
          var n = e.nodeName.replace(/delayscale/, "delayScale");
          /^data-in-*/.test(n)
            ? ((i.in = i.in || {}),
              (i.in[n.replace(/data-in-/, "")] = a(e.nodeValue)))
            : /^data-out-*/.test(n)
            ? ((i.out = i.out || {}),
              (i.out[n.replace(/data-out-/, "")] = a(e.nodeValue)))
            : /^data-*/.test(n) && (i[n.replace(/data-/, "")] = a(e.nodeValue));
        }),
      i
    );
  }
  function f(t, s, l) {
    var o = t.length;
    o
      ? (s.shuffle &&
          (t = (function (t) {
            for (
              var e, n, i = t.length;
              i;
              e = parseInt(Math.random() * i), n = t[--i], t[i] = t[e], t[e] = n
            );
            return t;
          })(t)),
        s.reverse && (t = t.toArray().reverse()),
        r.each(t, function (t, e) {
          var i = r(e);
          function a() {
            c(s.effect)
              ? i.css("visibility", "visible")
              : u(s.effect) && i.css("visibility", "hidden"),
              !--o && l && l();
          }
          var n = s.sync ? s.delay : s.delay * t * s.delayScale;
          i.text()
            ? setTimeout(function () {
                var t, e, n;
                (t = i),
                  (e = s.effect),
                  (n = a),
                  t
                    .addClass("animated " + e)
                    .css("visibility", "visible")
                    .show(),
                  t.one(
                    "webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend",
                    function () {
                      t.removeClass("animated " + e), n && n();
                    }
                  );
              }, n)
            : a();
        }))
      : l && l();
  }
  function s(t, e) {
    var s = this,
      l = r(t);
    (s.init = function () {
      (s.$texts = l.find(e.selector)),
        s.$texts.length ||
          ((s.$texts = r('<ul class="texts"><li>' + l.html() + "</li></ul>")),
          l.html(s.$texts)),
        s.$texts.hide(),
        (s.$current = r("<span>")
          .html(s.$texts.find(":first-child").html())
          .prependTo(l)),
        c(e.in.effect)
          ? s.$current.css("visibility", "hidden")
          : u(e.out.effect) && s.$current.css("visibility", "visible"),
        s.setOptions(e),
        (s.timeoutRun = null),
        setTimeout(function () {
          s.options.autoStart && s.start();
        }, s.options.initialDelay);
    }),
      (s.setOptions = function (t) {
        s.options = t;
      }),
      (s.triggerEvent = function (t) {
        var e = r.Event(t + ".tlt");
        return l.trigger(e, s), e;
      }),
      (s.in = function (t, e) {
        t = t || 0;
        var n,
          i = s.$texts.find(":nth-child(" + ((t || 0) + 1) + ")"),
          a = r.extend(!0, {}, s.options, i.length ? o(i[0]) : {});
        i.addClass("current"),
          s.triggerEvent("inAnimationBegin"),
          l.attr("data-active", i.data("id")),
          s.$current.html(i.html()).lettering("words"),
          "char" == s.options.type &&
            s.$current
              .find('[class^="word"]')
              .css({
                display: "inline-block",
                "-webkit-transform": "translate3d(0,0,0)",
                "-moz-transform": "translate3d(0,0,0)",
                "-o-transform": "translate3d(0,0,0)",
                transform: "translate3d(0,0,0)",
              })
              .each(function () {
                r(this).lettering();
              }),
          (n = s.$current
            .find('[class^="' + s.options.type + '"]')
            .css("display", "inline-block")),
          c(a.in.effect)
            ? n.css("visibility", "hidden")
            : u(a.in.effect) && n.css("visibility", "visible"),
          (s.currentIndex = t),
          f(n, a.in, function () {
            s.triggerEvent("inAnimationEnd"),
              a.in.callback && a.in.callback(),
              e && e(s);
          });
      }),
      (s.out = function (t) {
        var e = s.$texts.find(
            ":nth-child(" + ((s.currentIndex || 0) + 1) + ")"
          ),
          n = s.$current.find('[class^="' + s.options.type + '"]'),
          i = r.extend(!0, {}, s.options, e.length ? o(e[0]) : {});
        s.triggerEvent("outAnimationBegin"),
          f(n, i.out, function () {
            e.removeClass("current"),
              s.triggerEvent("outAnimationEnd"),
              l.removeAttr("data-active"),
              i.out.callback && i.out.callback(),
              t && t(s);
          });
      }),
      (s.start = function (t) {
        setTimeout(function () {
          s.triggerEvent("start"),
            (function e(n) {
              s.in(n, function () {
                var t = s.$texts.children().length;
                (n += 1),
                  !s.options.loop && t <= n
                    ? (s.options.callback && s.options.callback(),
                      s.triggerEvent("end"))
                    : ((n %= t),
                      (s.timeoutRun = setTimeout(function () {
                        s.out(function () {
                          e(n);
                        });
                      }, s.options.minDisplayTime)));
              });
            })(t || 0);
        }, s.options.initialDelay);
      }),
      (s.stop = function () {
        s.timeoutRun && (clearInterval(s.timeoutRun), (s.timeoutRun = null));
      }),
      s.init();
  }
  (r.fn.textillate = function (i, a) {
    return this.each(function () {
      var t = r(this),
        e = t.data("textillate"),
        n = r.extend(
          !0,
          {},
          r.fn.textillate.defaults,
          o(this),
          "object" == typeof i && i
        );
      e
        ? "string" == typeof i
          ? e[i].apply(e, [].concat(a))
          : e.setOptions.call(e, n)
        : t.data("textillate", (e = new s(this, n)));
    });
  }),
    (r.fn.textillate.defaults = {
      selector: ".texts",
      loop: !1,
      minDisplayTime: 2e3,
      initialDelay: 0,
      in: {
        effect: "fadeInLeftBig",
        delayScale: 1.5,
        delay: 50,
        sync: !1,
        reverse: !1,
        shuffle: !1,
        callback: function () {},
      },
      out: {
        effect: "hinge",
        delayScale: 1.5,
        delay: 50,
        sync: !1,
        reverse: !1,
        shuffle: !1,
        callback: function () {},
      },
      autoStart: !0,
      inEffects: [],
      outEffects: ["hinge"],
      callback: function () {},
      type: "char",
    });
})(jQuery);

